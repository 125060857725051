import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import sky from "../Images/sky_img.webp";
import skykids from "../Images/skykids.webp";
import skyteens from "../Images/skyteens.webp";
import { Container } from "@mui/material";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import sastang from "../Images/sastang.webp";
import wisdom from "../Images/wisdom.webp";

function Meetups() {
  return (
    <div style={{ width: "100%", backgroundColor: "goldenrod" }}>
      <div>
        <div
          style={{
            fontWeight: "bold",
            fontSize: "25px",
            height: "100px",
            textAlign: "center",
            verticalAlign: "center",
          }}
        >
          <label style={{ height: "100px", verticalAlign: "center" }}>
            Special Events
          </label>
        </div>
        {/* <div style={{ paddingBottom: "25px" }}> 
          <Container style={{ backgroundColor: "blanchedalmond" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm container>
                <Grid item xs container direction="column" spacing={2}>
                  <Grid item xs>
                    <Typography
                      gutterBottom
                      variant="h4"
                      component="div"
                      align="center"
                    >
                      SKY BMW MEETUPS <br />
                      (For SKY BMW Graduates Only)
                    </Typography>
                    <Typography variant="body2" gutterBottom></Typography>
                    <Typography>
                      <div aria-disabled="false" className="centered">
                        <Stack direction="row" spacing={1}>
                          <Chip
                            label="Sign Up"
                            component="a"
                            color="primary"
                            href="https://linktr.ee/artoflivinglondon"
                            target="_blank"
                            clickable
                          />
                        </Stack>
                      </div>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <img alt="complex" src={sky} width="300" />
              </Grid>
            </Grid>
          </Container>
        </div>
        <div style={{ paddingBottom: "25px" }}>
          <Container style={{ backgroundColor: "blanchedalmond" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm container>
                <Grid item xs container direction="column" spacing={2}>
                  <Grid item xs>
                    <Typography
                      gutterBottom
                      variant="h4"
                      component="div"
                      align="center"
                    >
                      SKY TEENS MEETUPS <br />
                      (For SKY Teens Graduates Only)
                    </Typography>
                    <Typography variant="body2" gutterBottom></Typography>
                    <Typography>
                      <div aria-disabled="false" className="centered">
                        <Stack direction="row" spacing={1}>
                          <Chip
                            label="Sign Up"
                            component="a"
                            color="primary"
                            href="https://linktr.ee/happyteens"
                            target="_blank"
                            clickable
                          />
                        </Stack>
                      </div>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <img alt="complex" src={skyteens} width="300" />
              </Grid>
            </Grid>
          </Container>
        </div>
        <div style={{ paddingBottom: "25px" }}>
          <Container style={{ backgroundColor: "blanchedalmond" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm container>
                <Grid item xs container direction="column" spacing={2}>
                  <Grid item xs>
                    <Typography
                      gutterBottom
                      variant="h4"
                      component="div"
                      align="center"
                    >
                      SKY KIDS MEETUPS <br />
                      (For SKY Kids Graduates Only)
                    </Typography>
                    <Typography variant="body2" gutterBottom></Typography>
                    <Typography>
                      <div aria-disabled="false" className="centered">
                        <Stack direction="row" spacing={1}>
                          <Chip
                            label="Sign Up"
                            component="a"
                            href="https://linktr.ee/artoflivingyouth"
                            color="primary"
                            target="_blank"
                            clickable
                          />
                        </Stack>
                      </div>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <img alt="complex" src={skykids} width="300" />
              </Grid>
            </Grid>
          </Container>
        </div>
        <div style={{ paddingBottom: "25px" }}>
          <Container style={{ backgroundColor: "blanchedalmond" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm container>
                <Grid item xs container direction="column" spacing={2}>
                  <Grid item xs>
                    <Typography
                      gutterBottom
                      variant="h4"
                      component="div"
                      align="center"
                    >
                      SATSANG <br />
                      Open To Everyone
                    </Typography>
                    <Typography variant="body2" gutterBottom></Typography>
                    <Typography>
                      <div aria-disabled="false" className="centered">
                        <Stack direction="row" spacing={1}>
                          <Chip
                            label="Join the Whatsapp Group"
                            component="a"
                            color="primary"
                            href="https://chat.whatsapp.com/Fk88As77ISuEdsP5nahmsQ"
                            target="_blank"
                            clickable
                          />
                        </Stack>
                      </div>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <img alt="complex" src={sastang} width="300" />
              </Grid>
            </Grid>
          </Container>
        </div>
        */}
        <div style={{ paddingBottom: "425px" }}>
          <Container style={{ backgroundColor: "blanchedalmond" }}>
            <Grid container spacing={2}>
              <Grid item>
                <Grid item xs container direction="column">
                  <Grid item xs>
                    <Typography
                      gutterBottom
                      variant="h4"
                      component="div"
                      align="center"
                      style={{
                        fontSize: "45px",
                        color: "#007FCE",
                      }}
                    >
                      <b>Yoga, Music and Mediation</b> <br />
                      Open To Everyone
                    </Typography>
                    <Typography variant="body2" gutterBottom></Typography>
                    <Typography>
                      <div aria-disabled="false" className="centered">
                        <Stack direction="row" spacing={1}>
                          <Chip
                            label="Sign Up"
                            component="a"
                            href="https://linktr.ee/aolspecialevents"
                            color="primary"
                            target="_blank"
                            clickable
                          />
                        </Stack>
                      </div>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item style={{ verticalAlign: "center" }}>
                <img alt="complex" src={sastang} width="380" />
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    </div>
  );
}

export default Meetups;
