import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import pfphoto from "../Images/IMG_5795.jpeg";
import pfphoto1 from "../Images/Akshay.jpg";
import { Container } from "@mui/material";
import pfphoto2 from "../Images/Shubha.jpg";
import pfphoto3 from "../Images/Evangeline.jpg";
import pfphoto4 from "../Images/Nirmala.jpg";
import pfphoto5 from "../Images/Jigisha.jpg";

function Team() {
  return (
    <div>
      <div
        style={{
          height: "150px",
          textAlign: "center",
          verticalAlign: "center",
        }}
      >
        <label
          style={{
            height: "100px",
            verticalAlign: "center",
            fontWeight: "bold",
            fontSize: "45px",
            color: "#007FCE",
          }}
        >
          MEET OUR VOLUNTEERS
        </label>
      </div>
      <div style={{ paddingBottom: "25px" }}>
        <Container style={{ backgroundColor: "burlywood" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography
                    align="center"
                    variant="h4"
                    style={{ fontWeight: "bold" }}
                  >
                    Teacher
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    align="center"
                  >
                    <b>Sowmya Kadaveru</b> is a Yoga & SKY breath Mediation
                    program teacher for all ages. She has been practicing yoga &
                    meditation since 2011. She is speech language pathologist by
                    profession at CPRI, London, ON. She is 500 Hours certified
                    Yoga instructor. She is pre-natal yoga and children yoga
                    certified teacher as well. She is been teaching these life
                    tranforming programs since 2015 and has positively impacted
                    youth & society at large.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <img alt="complex" src={pfphoto} width="400" />
            </Grid>
          </Grid>
        </Container>
      </div>
      <div style={{ paddingBottom: "25px" }}>
        <Container style={{ backgroundColor: "burlywood" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography
                    align="center"
                    variant="h4"
                    style={{ fontWeight: "bold" }}
                  >
                    Yoga Teacher
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    align="center"
                  >
                    <b>Nirmala</b> is a Mother, Wife, Hiker, and Yoga &
                    Meditation teacher. She has been practicing yoga &
                    meditation for the past 9+ years. She is an Information
                    Technology Program Manager at PwC, Tampa, Florida. She is
                    200 Hours certified Yoga instructor. She is also a Care for
                    Children (CFC) Tampa chapter lead. She is a volunteer and
                    conducts Yogathon and Meditation workshops and
                    community-building activities. She has been instrumental in
                    building a more inclusive community of problem solvers.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <img alt="complex" src={pfphoto4} width="400" />
            </Grid>
          </Grid>
        </Container>
      </div>
      <div style={{ paddingBottom: "25px" }}>
        <Container style={{ backgroundColor: "burlywood" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography
                    align="center"
                    variant="h4"
                    style={{ fontWeight: "bold" }}
                  >
                    Yoga Teacher
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    align="center"
                  >
                    <b>Shubha</b> is a certified Yoga Teacher from Sri Sri yoga
                    and Sivananda yoga. She has been volunteering at art of
                    living foundation spreading the love and positivity through
                    teaching yoga. She has beeen a member of art of living since
                    2004. She is also an IT professinal in Bay area, California
                    and understands the value of yoga and mediation in relieving
                    day to day work stress, thereby improving the quality of
                    work life balane.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <img alt="complex" src={pfphoto2} width="400" />
            </Grid>
          </Grid>
        </Container>
      </div>
      <div style={{ paddingBottom: "25px" }}>
        <Container style={{ backgroundColor: "burlywood" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography
                    align="center"
                    variant="h4"
                    style={{ fontWeight: "bold" }}
                  >
                    Yoga Teacher
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    align="center"
                  >
                    <b>Evangeline</b> Mangune lives in Toronto, Canada. She is a
                    Registered Massage Therapist, Yoga Teacher and Ayurveda
                    Educator. She customizes sessions and treatments to help
                    each client actualize their vision towards sustainable,
                    balanced health. She also leads Ayurveda and Yoga group
                    workshops and health retreats, on line and in person, and is
                    an Art of Living volunteer.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <img alt="complex" src={pfphoto3} width="400" />
            </Grid>
          </Grid>
        </Container>
      </div>
      <div style={{ paddingBottom: "25px" }}>
        <Container style={{ backgroundColor: "burlywood" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography
                    align="center"
                    variant="h4"
                    style={{ fontWeight: "bold" }}
                  >
                    Yoga Teacher
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    align="center"
                  >
                    <b>Jigisha Patel</b> has been a dedicated Art of Living yoga
                    teacher since 2019, known for a dynamic teaching style.
                    Alongside teaching, Jigisha volunteers for the
                    community-driven Happiness Course, promoting wellness. Each
                    morning, Jigisha conducts yoga classes that help people
                    start their day positively. Jigisha believes yoga offers
                    more than physical benefits, it's a tool for mental strength
                    and self-discovery. Jigisha’s ultimate goal is to help
                    others find inner peace through yoga.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <img alt="complex" src={pfphoto5} width="400" />
            </Grid>
          </Grid>
        </Container>
      </div>
      <div style={{ paddingBottom: "25px" }}>
        <Container style={{ backgroundColor: "burlywood" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography
                    align="center"
                    variant="h4"
                    style={{ fontWeight: "bold" }}
                  >
                    Teacher
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    align="center"
                  >
                    Dr.Akshya Vasudev is a psychiatrist and offers his services
                    for geriatric patients struggling with mental illnesses. He
                    recognizes the advantages of adding meditation and yoga to
                    the therapy of his patients. He is teacher for Art of Living
                    Foundation and hopes to bring the message of peach and love
                    to our one world family
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <img alt="complex" src={pfphoto1} width="400" />
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
}

export default Team;
