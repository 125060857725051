import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import sastang from "../Images/sastang.webp";
import whyskyimg from "../Images/WHYSKY.webp";
import { Slide } from "react-slideshow-image";
import img1 from "../Images/Testimonials/1.jpg";
import img2 from "../Images/Testimonials/4.jpg";
import img3 from "../Images/Testimonials/6.jpg";
import img4 from "../Images/Testimonials/8.jpg";
import img5 from "../Images/Testimonials/10.jpg";
import img6 from "../Images/Testimonials/11.jpg";
import img7 from "../Images/Testimonials/12.jpg";
import img8 from "../Images/Testimonials/14.jpg";
import img9 from "../Images/Testimonials/16.jpg";
import img10 from "../Images/Testimonials/18.jpg";
import img11 from "../Images/Testimonials/20.jpg";
import img12 from "../Images/Testimonials/21.jpg";
import img13 from "../Images/Testimonials/23.jpg";
import img14 from "../Images/Testimonials/25.jpg";
import img15 from "../Images/Testimonials/26.jpg";
import img16 from "../Images/Testimonials/28.jpg";
import img17 from "../Images/Testimonials/30.jpg";
import img18 from "../Images/Testimonials/32.jpg";
import img19 from "../Images/Testimonials/34.jpg";
import img20 from "../Images/Testimonials/36.jpg";
import img21 from "../Images/Testimonials/38.jpg";
import img22 from "../Images/Testimonials/40.jpg";
import img23 from "../Images/Testimonials/42.jpg";
import img24 from "../Images/Testimonials/44.jpg";
import img25 from "../Images/Testimonials/46.jpg";
import "../styles/slider.css";

function Meditate() {
  const slideImages = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
    img17,
    img18,
    img19,
    img20,
    img21,
    img22,
    img23,
    img24,
    img25,
  ];

  const properties = {
    duration: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    indicators: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <div style={{ backgroundColor: "antiquewhite" }}>
      <div className="slide-container">
        <Slide {...properties}>
          {slideImages.map((slideImage, index) => (
            <div className="each-slide" key={index}>
              <img alt="e1" src={slideImage} width={"100%"} height={"600"} />
            </div>
          ))}
        </Slide>
      </div>
      <div>
        <Container style={{ backgroundColor: "blanchedalmond" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography
                    gutterBottom
                    variant="h4"
                    component="div"
                    align="center"
                    style={{
                      fontSize: "45px",
                      color: "#007FCE",
                    }}
                  >
                    <b>How is Art of Living Changing Lives?</b> <br />
                  </Typography>
                  <Typography variant="body2" gutterBottom></Typography>
                  <Typography>
                    <div aria-disabled="false" className="centered">
                      <Stack direction="row" spacing={1}>
                        <Chip
                          label="Video Testimonials"
                          component="a"
                          href="https://linktr.ee/video.testimonials"
                          color="primary"
                          target="_blank"
                          clickable
                        />
                      </Stack>
                    </div>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div className="head-text">
        <img
          alt="e1"
          src={whyskyimg}
          width={"100%"}
          height={"600"}
          style={{ opacity: 0.25 }}
        />
        <div
          className="contact-text-on-image-new"
          style={{ textAlign: "center" }}
        >
          <Container>
            <h1
              style={{
                fontWeight: "bold",
                fontSize: "45px",
                color: "#007FCE",
                textAlign: "center",
              }}
            >
              Impact of SKY on Health and Wellbeing
            </h1>

            <h2>
              Science-backed benefits of SKY, taught in the Art of Living Part
              1, shown in over 100 independent studies
            </h2>
          </Container>
        </div>
      </div>
    </div>
  );
}

export default Meditate;
