import React from "react";
import hpi from "../Images/Home_page_new.jpg.webp";
import guru from "../Images/guruji.jpg";
import sky from "../Images/sky_img.webp";
// import sahaj from "../Images/sahaj.gif";
import skykids from "../Images/skykids.webp";
import ssyoga from "../Images/ssyoga.gif";
import contactimg from "../Images/contactus.gif";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useState } from "react";
import "../styles/skstyles.css";
import emailjs from "@emailjs/browser";
import { withStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import StarBorder from "@mui/icons-material/StarBorder";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ReactPlayer from "react-player";
import srisrivideo from "../videos/newvideo.mp4";
import yogapic from "../Images/yogapic.jpg";
import { CenterFocusStrong } from "@material-ui/icons";

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "black",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
      },
      "&:hover fieldset": {
        borderColor: "black",
      },
      "&.Mui-focused fieldset": {
        borderColor: "green",
      },
    },
  },
})(TextField);

function Home() {
  const [name, setname] = useState("");
  const [phone, setphone] = useState("");
  const [email, setemail] = useState("");
  const [message, setmessage] = useState("");
  const [submission, setsubmission] = useState("false");

  const [errors, setErrors] = useState({});
  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs.send(
      "service_pqy2g99",
      "template_2rs9mxv",
      {
        from_name: name,
        reply_to: email,
        phone_no: phone,
        message_html: message,
      },
      "CtSb0VbLc3M2rysQK"
    );
    setname("");
    setemail("");
    setphone("");
    setmessage("");
    setsubmission("true");
    setTimeout(() => {
      setsubmission("false");
    }, 2000);
  };

  return (
    <div>
      <div className="head-text" style={{ backgroundColor: "wheat" }}>
        <img alt="e1" src={hpi} width={"100%"} height={"400"} />
        <div className="text-on-image">
          <h1>
            Eliminate your stress{" "}
            <span style={{ fontWeight: "bold", color: "crimson" }}>
              @Breathe Strong
            </span>
          </h1>
        </div>
        <div className="text-on-image-section1">
          <Typography style={{ wordWrap: "break-word" }}>
            <CheckCircleIcon
              style={{ verticalAlign: "bottom", color: "#FF7E00" }}
            />
            Relax your mind with special breathing practices
          </Typography>
          <br />
          <Typography style={{ wordWrap: "break-word" }}>
            <CheckCircleIcon
              style={{ verticalAlign: "bottom", color: "#FF7E00" }}
            />
            Immerse yourself in a meditation experience
          </Typography>
          <br />
          <Typography style={{ wordWrap: "break-word" }}>
            <CheckCircleIcon
              style={{ verticalAlign: "bottom", color: "#FF7E00" }}
            />
            Learn key techniques to manage your mind
          </Typography>
          <br />
          <div aria-disabled="false">
            <Stack direction="row" spacing={1}>
              <Chip
                label="RSVP FOR A FREE 75 MIN SESSION"
                component="a"
                color="primary"
                href="https://www.eventbrite.com/e/beyond-breath-introduction-to-sky-breath-meditation-program-online-tickets-144276124773"
                target="_blank"
                clickable
              />
            </Stack>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          backgroundColor: "bisque",
        }}
      >
        <Grid container>
          <Grid item lg={8} sm container>
            <Grid item xs container direction="column">
              <Grid item xs>
                <Typography
                  gutterBottom
                  variant="h4"
                  component="div"
                  align="center"
                  style={{
                    fontSize: "45px",
                    color: "#007FCE",
                  }}
                >
                  <b>Yoga, Music and Mediation</b> <br />
                  Open To Everyone
                </Typography>
                <Typography variant="body2" gutterBottom></Typography>
                <Typography>
                  <div aria-disabled="false" className="centered">
                    <Stack direction="row" spacing={1}>
                      <Chip
                        label="Sign Up"
                        component="a"
                        href="https://linktr.ee/aolspecialevents"
                        color="primary"
                        target="_blank"
                        clickable
                      />
                    </Stack>
                  </div>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={4}>
            <div>
              <img alt="e1" src={yogapic} width={"100%"} height={"400"} />
            </div>
          </Grid>
        </Grid>
      </div>
      <div style={{ width: "100%", backgroundColor: "wheat" }}>
        <div
          style={{
            fontWeight: "bold",
            fontSize: "25px",
            height: "100px",
            textAlign: "center",
            verticalAlign: "center",
          }}
        >
          <label
            style={{
              height: "100px",
              verticalAlign: "center",
              fontSize: "35px",
              fontWeight: "bold",
              color: "#007FCE",
            }}
          >
            PROGRAMS
          </label>
        </div>
        <div style={{ paddingLeft: "20px", paddingBottom: "20px" }}>
          <Grid
            container
            spacing={{ xs: 1, sm: 3, md: 3, lg: 3 }}
            columns={{ xs: 1, sm: 3, md: 3, lg: 3 }}
            style={{ paddingBottom: "20px" }}
          >
            <Grid item xs={1}>
              <Card sx={{ maxWidth: 370 }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="300"
                    image={sky}
                    alt="green iguana"
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      sx={{ textAlign: "center" }}
                      style={{
                        fontSize: "25px",
                        color: "#007FCE",
                      }}
                    >
                      <b>ART OF LIVING </b> <br />
                      PART 1
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      style={{
                        fontSize: "15px",
                        color: "#007FCE",
                      }}
                    >
                      Discover Gurudev Sri Sri Ravi Shankar's <br />
                      <b>ancient secret to modern well-being</b>
                      <br />
                      <br />
                    </Typography>
                    <div>
                      <Typography style={{ wordWrap: "break-word" }}>
                        <CheckCircleIcon
                          style={{ verticalAlign: "bottom", color: "#FF7E00" }}
                        />
                        Reduce stress, anxiety, and depression
                      </Typography>
                      <br />
                      <Typography style={{ wordWrap: "break-word" }}>
                        <CheckCircleIcon
                          style={{ verticalAlign: "bottom", color: "#FF7E00" }}
                        />
                        Increase your energy levels
                      </Typography>
                      <br />
                      <Typography style={{ wordWrap: "break-word" }}>
                        <CheckCircleIcon
                          style={{ verticalAlign: "bottom", color: "#FF7E00" }}
                        />
                        Improve your sleep
                      </Typography>
                      <br />
                    </div>
                    <Typography
                      variant="body2"
                      color="GrayText"
                      style={{
                        fontSize: "15px",
                        color: "#007FCE",
                      }}
                    >
                      What's included
                    </Typography>
                    <div>
                      <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 2 }}>
                          <ListItemIcon>
                            <StarBorder
                              style={{
                                color: "#007FCE",
                              }}
                            ></StarBorder>
                          </ListItemIcon>
                          <ListItemText primary="Daily guided  energying yoga and meditation" />
                        </ListItemButton>
                        <ListItemButton sx={{ pl: 2 }}>
                          <ListItemIcon>
                            <StarBorder
                              style={{
                                color: "#007FCE",
                              }}
                            ></StarBorder>
                          </ListItemIcon>
                          <ListItemText primary="Pranayama" />
                        </ListItemButton>
                        <ListItemButton sx={{ pl: 2 }}>
                          <ListItemIcon>
                            <StarBorder
                              style={{
                                color: "#007FCE",
                              }}
                            ></StarBorder>
                          </ListItemIcon>
                          <ListItemText primary="SKY Breath Meditation" />
                        </ListItemButton>
                        <ListItemButton sx={{ pl: 2 }}>
                          <ListItemIcon>
                            <StarBorder
                              style={{
                                color: "#007FCE",
                              }}
                            ></StarBorder>
                          </ListItemIcon>
                          <ListItemText primary="5 Keys to Joyful life" />
                        </ListItemButton>
                        <ListItemButton sx={{ pl: 2 }}>
                          <ListItemIcon>
                            <StarBorder
                              style={{
                                color: "#007FCE",
                              }}
                            ></StarBorder>
                          </ListItemIcon>
                          <ListItemText primary="Life time access" />
                        </ListItemButton>
                      </List>
                    </div>
                    <Typography>
                      <div aria-disabled="false" className="centered">
                        <Stack direction="row" spacing={1}>
                          <Chip
                            label="Sign Up"
                            component="a"
                            color="primary"
                            href="https://linktr.ee/artoflivinglondon"
                            target="_blank"
                            clickable
                          />
                        </Stack>
                      </div>
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            {/* <Grid item xs={1} sm={4} md={4}>
              <Card sx={{ maxWidth: 345 }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="300"
                    image={sahaj}
                    alt="green iguana"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="span">
                      SAHAJ SAMADHI
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Sahaj means effortless. Samadhi means a state of true
                      meditation. Simply put, Sahaj Samadhi is an effective,
                      personalized way to easily enter into a deep meditative
                      state. Using the power of ancient sound, or mantra, you
                      can effortlessly cut through mental chatter and connect
                      with a state of deep rest and relaxation
                    </Typography>
                    <Typography>
                      <div aria-disabled="false" className="centered">
                        <Stack direction="row" spacing={1}>
                          <Chip
                            label="Sign Up"
                            component="a"
                            color="primary"
                            href="https://members.us.artofliving.org/us-en/course?courseTypeFilter=%7B%22name%22%3A%22Sahaj%20Samadhi%20Meditation%22%2C%22value%22%3A%22999649%22%7D&instructorFilter=%7B%22value%22%3A%220014600001XcEafAAF%22%2C%22label%22%3A%22JP%20Samala%22%7D"
                            target="_blank"
                            clickable
                          />
                        </Stack>
                      </div>
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid> */}
            <Grid item xs={1}>
              <Card sx={{ maxWidth: 370 }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="300"
                    image={skykids}
                    alt="green iguana"
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      align="center"
                      style={{
                        fontSize: "25px",
                        color: "#007FCE",
                      }}
                    >
                      <b>Kids and Teens</b> <br /> Yoga and Meditation
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      style={{
                        fontSize: "15px",
                        color: "#007FCE",
                      }}
                    >
                      We offer different programs from the age 5-17 years
                      <br />
                      <br />
                      <b>Children learn tools and skills to:</b>
                      <div>
                        <br />
                        <Typography style={{ wordWrap: "break-word" }}>
                          <CheckCircleIcon
                            style={{
                              verticalAlign: "bottom",
                              color: "#FF7E00",
                            }}
                          />
                          Release stress and negative emotion
                        </Typography>
                        <br />
                        <Typography style={{ wordWrap: "break-word" }}>
                          <CheckCircleIcon
                            style={{
                              verticalAlign: "bottom",
                              color: "#FF7E00",
                            }}
                          />
                          Boost concentration and memory
                        </Typography>
                        <br />
                        <Typography style={{ wordWrap: "break-word" }}>
                          <CheckCircleIcon
                            style={{
                              verticalAlign: "bottom",
                              color: "#FF7E00",
                            }}
                          />
                          Build self-confidence
                        </Typography>
                        <br />
                        <Typography style={{ wordWrap: "break-word" }}>
                          <CheckCircleIcon
                            style={{
                              verticalAlign: "bottom",
                              color: "#FF7E00",
                            }}
                          />
                          Handle peer pressure and bullying
                        </Typography>
                        <br />
                        <Typography style={{ wordWrap: "break-word" }}>
                          <CheckCircleIcon
                            style={{
                              verticalAlign: "bottom",
                              color: "#FF7E00",
                            }}
                          />
                          Strengthen human values and social skills
                        </Typography>
                        <br />
                      </div>
                    </Typography>
                    <Typography>
                      <div aria-disabled="false" className="centered">
                        <Stack direction="row" spacing={1}>
                          <Chip
                            label="Sign Up"
                            component="a"
                            color="primary"
                            href="https://linktr.ee/artoflivingyouth"
                            target="_blank"
                            clickable
                          />
                        </Stack>
                      </div>
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            {/*  <Grid item xs={1}>
              <Card sx={{ maxWidth: 345 }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="300"
                    image={skyteens}
                    alt="green iguana"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="span">
                      SKY TEENS 13-17 YR OLDS
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      The SKY Teens programs helps students discover greater
                      self-confidence, resilience and a set of tools that will
                      carry them through all of life’s ups and downs. Through
                      meditation, breathing techniques and interactive
                      processes...
                      <br />
                      <br />
                    </Typography>
                    <Typography>
                      <div aria-disabled="false" className="centered">
                        <Stack direction="row" spacing={1}>
                          <Chip
                            label="Sign Up"
                            component="a"
                            color="primary"
                            href="https://linktr.ee/happyteens"
                            target="_blank"
                            clickable
                          />
                        </Stack>
                      </div>
                    </Typography>
                  </CardContent> 
                </CardActionArea>
              </Card>
            </Grid>*/}
            <Grid alignItems="center" item xs={1}>
              <Card sx={{ maxWidth: 370 }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="300"
                    image={ssyoga}
                    alt="green iguana"
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      align="center"
                      style={{
                        fontSize: "25px",
                        color: "#007FCE",
                      }}
                    >
                      <b>Daily SRI SRI YOGA</b> Breathing and Meditation Classes
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      style={{
                        fontSize: "15px",
                        color: "#007FCE",
                      }}
                    >
                      Sri Sri Yoga is a beautiful practice which blends
                      chanting, energizing breathing exercises (to optimize
                      functioning of respiratory channels), gentle joint
                      exercises, yoga postures, and meditation
                      <br />
                      <br />
                    </Typography>
                    <Typography>
                      <div aria-disabled="false" className="centered">
                        <Stack direction="row" spacing={1}>
                          <Chip
                            label="Contact @ 226-582-6067"
                            component="a"
                            color="primary"
                          />
                        </Stack>
                      </div>
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          backgroundColor: "bisque",
        }}
      >
        <Grid container spacing={1}>
          <Grid item lg={4}>
            <div className="player-wrapper">
              <ReactPlayer
                className="react-player fixed-bottom"
                url={srisrivideo}
                width="100%"
                height="100%"
                controls={true}
              />
            </div>
          </Grid>
          <Grid item lg={8} sm container>
            <Grid item xs container direction="column">
              <Grid item xs>
                <Typography gutterBottom variant="subtitle1" component="div">
                  "The Art of Living is more of a principle, a philosophy of
                  living life to its fullest. It is more a movement than an
                  organization. Its core value is to find peace within oneself
                  and to unite people in our society – of different cultures,
                  traditions, religions, nationalities; and thus reminding us
                  all that we have one goal to uplift human life everywhere."
                </Typography>
                <Typography variant="body2" gutterBottom></Typography>
                <Typography variant="body2" style={{ fontWeight: "bold" }}>
                  Sri Sri Ravishankar - Founder and Spiritual Leader
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div className="head-text">
        <img alt="e2" src={contactimg} width={"100%"} height={"800"} />
        <div className="contact-text-on-image">
          <h1 style={{ fontWeight: "bold" }}>
            CONTACT <br /> ART OF LIVING <br /> LONDON ONTARIO
          </h1>
          <h4>
            sowmya.kadaveru@artofliving.org <br /> 226-582-6067
          </h4>
        </div>
        <div className="form-text-on-image">
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "45ch" },
            }}
            autoComplete="off"
          >
            <CssTextField
              id="standard-name-input"
              label="Name"
              type="Name"
              required
              variant="outlined"
              onChange={(e) => setname(e.target.value)}
              value={name}
            />
            <br />
            <CssTextField
              id="standard-email-input"
              label="Email"
              type="Email"
              required
              variant="outlined"
              onChange={(e) => setemail(e.target.value)}
              value={email}
            />
            <br />
            <CssTextField
              id="standard-phone-input"
              label="Phone"
              type="Phone"
              required
              variant="outlined"
              onChange={(e) => setphone(e.target.value)}
              value={phone}
            />
            <br />
            <CssTextField
              id="standard-message-input"
              label="Message"
              type="Message"
              multiline
              maxRows={6}
              variant="outlined"
              onChange={(e) => setmessage(e.target.value)}
              value={message}
            />
            <br />
            <br />
            <label
              className={submission === "false" ? "hidden" : undefined}
              style={{ fontWeight: "bold" }}
              id="thanksinfoID"
            >
              Thanks for submission. We will contact you soon!!
            </label>
            <br />
            <Button
              variant="contained"
              color="success"
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              submit
            </Button>
          </Box>
        </div>
      </div>
    </div>
  );
}

export default Home;
