import React from "react";
import {
  AppBar,
  Toolbar,
  CssBaseline,
  Typography,
  makeStyles,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import DrawerComponent from "./Drawer";
import aol from "../Images/aol.webp";

const useStyles = makeStyles((theme) => ({
  navlinks: {
    marginLeft: theme.spacing(5),
    display: "flex",
  },
  logo: {
    flexGrow: "1",
    cursor: "pointer",
  },
  link: {
    textDecoration: "none",
    color: "white",
    fontSize: "15px",
    marginLeft: theme.spacing(5),
    "&:hover": {
      color: "yellow",
      borderBottom: "1px solid white",
    },
  },
}));

function Navbar() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <AppBar position="static" style={{ backgroundColor: "teal" }}>
      <CssBaseline />
      <Toolbar>
        <img src={aol} height={50} width={100} alt="Logo" />
        &nbsp;&nbsp;
        <Typography
          variant="h6"
          className={classes.logo}
          style={{ fontWeight: "bold", textAlign: "center" }}
        >
          London Canada
        </Typography>
        {isMobile ? (
          <DrawerComponent />
        ) : (
          <div className={classes.navlinks}>
            <Link to="/" className={classes.link}>
              Home
            </Link>
            <Link to="/meditate" className={classes.link}>
              Testimonials
            </Link>
            <Link to="/team" className={classes.link}>
              Meet the Team
            </Link>
            <Link to="/meetups" className={classes.link}>
              Special Events
            </Link>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
}
export default Navbar;
