import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./component/Navbar";
import Home from "./pages/home";
import Meditate from "./pages/meditate";
import Meetups from "./pages/meetups";
import Programs from "./pages/programs";
import Team from "./pages/team";

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/Programs" element={<Programs />} />
        <Route path="/Meditate" element={<Meditate />} />
        <Route path="/Team" element={<Team />} />
        <Route path="/Meetups" element={<Meetups />} />
      </Routes>
    </Router>
  );
}
export default App;
